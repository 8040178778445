import React from "react";
import supportColumns from "./SupportColumns";
import { Pagination, Table } from "antd";
import { useSupportProvider } from "./SupportContext";

const Listing = () => {
  const columns = supportColumns();
  const { tickets, isFetchingTickets } = useSupportProvider();

  return (
    <div style={{ marginTop: "20px" }}>
      <Table
        columns={columns}
        dataSource={tickets}
        loading={isFetchingTickets}
        pagination={false}
      />
      <div
        style={{
          marginTop: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination defaultCurrent={1} total={50} />
      </div>
    </div>
  );
};

export default Listing;
