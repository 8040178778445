import React, { createContext, useContext } from "react";
import useSupport from "../../hooks/useSupport";

const SupportContext = createContext(undefined);

export const SupportProvider = ({ children }) => {
  const {
    tickets,
    ticketStats,
    isFetchingTickets,

    selectedTicket,
    setSelectedTicket,

    openTicketModel,
    closeTicketModel,
    isTicketModelOpen,

    sendMessage,
    fetchMessages,
    changeStatus,
  } = useSupport();

  return (
    <SupportContext.Provider
      value={{
        tickets,
        ticketStats,
        isFetchingTickets,
    
        selectedTicket,
        setSelectedTicket,
    
        openTicketModel,
        closeTicketModel,
        isTicketModelOpen,

        sendMessage,
        fetchMessages,
        changeStatus,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export const useSupportProvider = () => {
  const context = useContext(SupportContext);
  if (!context) {
    throw new Error("useSupportProvider must be used within a SupportProvider");
  }
  return context;
};
