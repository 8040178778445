import { SendOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

const TicketChatPanel = ({ticket, sendMessage, fetchMessages}) => {

  const [messages, setMessages] = useState([]);
  const [messageBody, setMessageBody] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFetchMessages = async () => {
    setIsFetching(true);
    fetchMessages(ticket.uuid)
    .then((res) => {
      if (res)
        setMessages(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setIsFetching(false);
    });
  };

  const handleSendMessage = async () => {
    if (!messageBody) return;
    if (messageBody === "") return;

    setIsSending(true);
    await sendMessage(ticket.uuid, messageBody);
    setIsSending(false);
    setMessageBody("");

    handleFetchMessages();
  };

  useEffect(() => {
    handleFetchMessages();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  return (
    <div className="d-flex flex-column gap-2" style={{ height: "60vh" }}>
      <div
        className="d-flex flex-column p-2 gap-1 border border-secondary rounded overflow-auto"
        style={{ maxHeight: "49vh", height: "49vh", scrollbarWidth: "none" }}
      >
        <div style={{ marginTop: "auto" }} className="d-flex flex-column gap-1">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`py-2 px-4 w-75 rounded-pill ${
                msg.userType === "USER" ? "align-self-end" : "align-self-start"
              }`}
              style={{ backgroundColor: msg.userType === "USER" ? "#d9edf7" : "#f0f0f0" }}
            >
              {msg.message}
              <div className="text-muted small text-end">
                {dayjs(msg?.createdAt).format("YYYY-MM-DD hh:mm A")}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="d-flex flex-row align-items-end gap-2">
        <textarea
          className="form-control"
          placeholder="message..."
          value={messageBody}
          disabled={isSending || isFetching}
          style={{ resize: "none", scrollbarWidth: "none" }}
          rows="1"
          onChange={(e) => setMessageBody(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        ></textarea>

        <button
          className="btn btn-primary"
          onClick={handleSendMessage}
          disabled={isSending || isFetching}
        >
          <SendOutlined />
        </button>
      </div>
    </div>

  )
};

export default TicketChatPanel;
