import { Badge } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useSupportProvider } from "./SupportContext";

const SupportColumns = () => {
  const { setSelectedTicket, openTicketModel } = useSupportProvider();
  const colors = {
    MEDIUM: "cyan",
    HIGH: "red",
    LOW: "green",
    PENDING: "yellow",
    IN_PROGRESS: "blue",
    CLOSED: "green",
  };
  const texts = {
    MEDIUM: "Medium",
    HIGH: "High",
    LOW: "Low",
    PENDING: "Pending",
    IN_PROGRESS: "In Progress",
    CLOSED: "Closed",
  };
  return [
    {
      title: "Ticket Number",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "50%",
    },
    {
      title: <div style={{ paddingLeft: "15px" }}>Priority</div>,
      dataIndex: "priority",
      render: (value) => {
        let color = colors[value] || "gray",
          text = texts[value] || value;
        return <Badge text={text} color={color} />;
      },
    },
    {
      title: <div style={{ paddingLeft: "15px" }}>Status</div>,
      dataIndex: "status",
      render: (value) => {
        let color = colors[value] || "gray",
          text = texts[value] || value;
        return <Badge text={text} color={color} />;
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <EyeOutlined
            style={{ fontSize: "12pt", color: "#1877F2" }}
            onClick={() => {
              setSelectedTicket(record);
              openTicketModel();
            }}
          />
        );
      },
    },
  ];
};

export default SupportColumns;
