import { Button } from "antd";

const TicketAttachmentPanel = ({ticket}) => {

  const resolveAttachmentName = (attachment) => {
    const split = attachment.split("/support/");
    return split[split.length - 1];
  };

  return(
    <div className="d-flex flex-column gap-2">
      <div className="fs-5 fw-semibold text-dark">Attachments</div>
      
      <div className="d-flex flex-column gap-2">
        {ticket?.attachment?.map((attachment, index) => (
          <Button
            key={index}
            onClick={() => window.open(attachment, "_blank")}
          >
            { resolveAttachmentName(attachment) }
          </Button>
        ))}
      </div>
    </div>
  )
};

export default TicketAttachmentPanel;