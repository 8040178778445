import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { StyledNavbar, StyledNavLink } from './styles';
import logo from '../../assets/logo.svg';
import logOut from '../../assets/LogOut.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { urls } from '../../containers/Router/urls';

const NavbarComp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <StyledNavbar bg="primary" expand="lg">
        <Navbar.Brand href={urls.listOrganizations}>
          <img src={logo} width="95" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="mr-auto" style={{ marginLeft: '2rem' }}>
            <StyledNavLink
              onClick={() => {
                history.push(`/admin/organizations`);
              }}
            >
              <span>Dashboard</span>
            </StyledNavLink> 

            <StyledNavLink
              onClick={() => {
                history.push(`/admin/support`);
              }}
            >
              <span>Support</span>
            </StyledNavLink>

            <StyledNavLink
              onClick={() => {
                dispatch({ type: 'auth/logout' });
                history.push(urls.login);
              }}
            >
              <span>Log Out &nbsp;&nbsp;</span>
              <img src={logOut} alt="icon" width="25px" />
            </StyledNavLink>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    </>
  );
};

export default NavbarComp;
