import dayjs from "dayjs";
import { Select } from "antd";

const TicketViewPanel = ({ticket, changeStatus}) => {

  
  return (
    <div className="d-flex flex-column gap-3">

      <div className="fs-5 fw-semibold text-dark">Ticket Details</div>

      <div className="d-flex flex-column flex-sm-row gap-3">
        <div className="d-grid gap-1">
          <div className="text-secondary fw-semibold small">Ticket Number</div>
          <div className="text-dark small">{ticket?.ticketNumber}</div>
        </div>

        <div className="d-grid gap-1">
          <div className="text-secondary fw-semibold small">Created At</div>
          <div className="text-dark small">{dayjs(ticket?.createdAt).format("YYYY-MM-DD hh:mm A")}</div>
        </div>

        <div className="d-grid gap-1">
          <div className="text-secondary fw-semibold small">Priority</div>
          <div className="text-dark small">{ticket?.priority}</div>
        </div>

        <div className="d-grid gap-1">
          <div className="text-secondary fw-semibold small">Status</div>
          <Select
            style={{ width: "100%" }}
            defaultValue={ticket?.status}
            onChange={(value) => {
              if (ticket?.status !== value)
                changeStatus(ticket?.uuid, value);
            }}
          >
            <Select.Option value="PENDING">Pending</Select.Option>
            <Select.Option value="IN_PROGRESS">In-progress</Select.Option>
            <Select.Option value="CLOSED">Closed</Select.Option>
          </Select>
        </div>
      </div>

      <div className="d-flex flex-column gap-1">
        <div className="text-secondary fw-semibold small">Title</div>
        <div className="text-dark small">{ticket?.title}</div>
      </div>

      <div className="d-flex flex-column gap-1">
        <div className="text-secondary fw-semibold small">Description</div>
        <div
          className="text-dark small text-justify overflow-auto border border-secondary p-2 rounded"
          style={{ height: "30vh" }}
        >
          {ticket?.description}
        </div>
      </div>

  </div>

  )
};

export default TicketViewPanel;