import React from "react";
import Header from "./widgets/listing/Header";
import Listing from "./widgets/listing/Listing";
import TicketModal from "./widgets/listing/TicketModal";
import { SupportProvider } from "./widgets/listing/SupportContext";
// import ComingSoon from "../../widgets/CommingSoon/ComingSoon";

const Index = () => {
  return (
    <div className="d-flex px-5 tw-text-gray py-4 flex-column">
      <SupportProvider>
       <Header />
       <Listing />
       <TicketModal />
      </SupportProvider>
      {/* <ComingSoon /> */}
    </div>
  );
};

export default Index;
