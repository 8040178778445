import React from "react";
// import { Button } from "antd";
// import { useNavigate } from "react-router-dom";
import { useSupportProvider } from "./SupportContext";

const Header = () => {
  // const navigate = useNavigate();
  const { ticketStats } = useSupportProvider();
  return (
    <div>
      {/* <div
        className="d-flex flex-row justify-content-between wf align-items-center"
        style={{ width: "100%" }}
      >
        <div className="d-flex flex-column">
          <span className="fs-4">Help & Support</span>
          <span className="fs-7" style={{ color: "#9e9e9e" }}>
            We're here to assist you with any questions or issues.
          </span>
        </div>
        <Button
          type="primary"
          onClick={() => {
            navigate("create");
          }}
        >
          Add Ticket
        </Button>
      </div> */}
      <div
        className="d-flex align-items-center font-sm justify-content-end"
        style={{ marginTop: "30px" }}
      >
        <span className="fw-normal fs-14px">Total Tickets:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.totalTickets || 0}</span>
        <span className="fw-normal fs-14px"> || </span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">High Priority:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.highPriority || 0}</span>
        <span className="fw-normal fs-14px"> |</span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">Medium Priority:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.mediumPriority || 0}</span>
        <span className="fw-normal fs-14px"> |</span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">Low Priority:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.lowPriority || 0}</span>
        <span className="fw-normal fs-14px"> || </span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">InProgress:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.inProgressStatus || 0}</span>
        <span className="fw-normal fs-14px"> |</span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">Pending:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.pendingStatus || 0}</span>
        <span className="fw-normal fs-14px"> | </span>&nbsp;&nbsp;
        <span className="fw-normal fs-14px">Closed:</span>
        <span className="fw-bold fs-14px mx-2">{ticketStats?.closedStatus || 0}</span>
      </div>
    </div>
  );
};

export default Header;
