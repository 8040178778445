import React from "react";
import { Modal, Tabs } from "antd";
import { useSupportProvider } from "./SupportContext";
import TicketViewPanel from "./TicketViewPanel";
import TicketChatPanel from "./TicketChatPanel";
import TicketAttachmentPanel from "./TicketAttachmentPanel";

const TicketModal = () => {
  const { selectedTicket, isTicketModelOpen, closeTicketModel, sendMessage, fetchMessages, changeStatus } = useSupportProvider();

  const tabItems = [
    {
      key: '1',
      label: 'Ticket',
      children: <TicketViewPanel ticket={selectedTicket} changeStatus={changeStatus} />,
    },
    {
      key: '2',
      label: 'Attachments',
      children: <TicketAttachmentPanel ticket={selectedTicket}/>,
    },
    {
      key: '3',
      label: 'Messages',
      children:
        <TicketChatPanel
          ticket={selectedTicket}
          sendMessage={sendMessage}
          fetchMessages={fetchMessages}
        />,
    },
  ];

  return (
    <Modal
      title={selectedTicket?.ticketNumber}
      open={isTicketModelOpen}
      onCancel={closeTicketModel}
      width={700}
      footer={null}
      destroyOnClose={true}
    >
      <div style={{ minHeight: "65vh" }}>
        <Tabs size="large" defaultActiveKey="1" items={tabItems} />
      </div>
    </Modal>
  );
};

export default TicketModal;
