import axios, { baseUrl } from "../utils/axios";

export const getAllSupportTicketsByAdmin = async() => {
  return await axios.get(`${baseUrl}/api/v2/support/get-admin-support-tickets/`);
};

export const addMessageToSupportTicketByAdmin = async(ticketId, message) => {
  return await axios.post(`${baseUrl}/api/v2/support/add-admin-support-message/${ticketId}`, { message });
};

export const getSupportMessagesByTicketId = async(ticketId) => {
  return await axios.get(`${baseUrl}/api/v2/support/get-support-ticket-messages/${ticketId}`);
};

export const updateSupportTicketStatus = async(ticketId, status) => {
  return await axios.post(`${baseUrl}/api/v2/support/update-support-ticket-status/${ticketId}`, { status });
};