import { message } from "antd";
import {
  addMessageToSupportTicketByAdmin,
  getAllSupportTicketsByAdmin,
  getSupportMessagesByTicketId,
  updateSupportTicketStatus,
} from "../../../api/apiMethods";
import { useEffect, useState } from "react";

const useSupport = () => {
  const [isTicketModelOpen, setTicketModelOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketStats, setTicketStats] = useState({});
  const [isFetchingTickets, setIsFetchingTickets] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});

  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    console.log(selectedTicket);
  }, [selectedTicket]);

  const fetchTickets = async () => {
    setIsFetchingTickets(true);

    getAllSupportTicketsByAdmin()
    .then((res) => {
      if (res.status === 200) {
        const tickets = res.data.data.tickets;
        tickets.forEach((ticket) => {
          ticket.ticketNumber = "STN-"+ticket.id;
        });
        setTickets(tickets);
        setTicketStats(res.data.data.stats);
      }
    }).finally(() =>{
      setIsFetchingTickets(false);
    });
  }

  const openTicketModel = () => {
    setTicketModelOpen(true);
  };

  const closeTicketModel = () => {
    setTicketModelOpen(false);
  };

  const fetchMessages = async (ticketId) => {
    return await getSupportMessagesByTicketId(ticketId)
    .then((res) => {
      if (res.status === 200)
      {  console.log(res.data.data);
        return res.data.data;}
      else 
        return false;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  };

  const sendMessage = async (ticketId, messageBody) => {
    await addMessageToSupportTicketByAdmin(ticketId, messageBody)
    .then((res) => {
      if (res.status === 200)
        message.success("Message sent successfully", 3);
      else 
        message.error("Error while sending message", 3);
    })
    .catch((err) => {
      console.log(err);
      message.error("Error while sending message", 3);
    });
  };

  const changeStatus = (ticketId, status) => {
    updateSupportTicketStatus(ticketId, status)
    .then((res) => {
      if (res.status === 200){
        message.success("Status updated successfully", 3);
        fetchTickets();
      }
      else 
        message.error("Error while updating status", 3);
    })
    .catch((err) => {
      console.log(err);
      message.error("Error while updating status", 3);
    });
  };

  return {
    tickets,
    ticketStats,
    isFetchingTickets,

    selectedTicket,
    setSelectedTicket,

    openTicketModel,
    closeTicketModel,
    isTicketModelOpen,

    sendMessage,
    fetchMessages,
    changeStatus,
  };
};

export default useSupport;
